import { SvgIcon } from '@mui/material'

const TlichoIconAtom = () => {
	return (
		<SvgIcon viewBox="0 0 65 62" width={65} height={62}>
			<path
				fill="#3b0f67"
				d="M32.2915 0C14.5596 0 0.185547 14.4454 0.185547 32.2655C0.185547 39.9393 2.85172 46.9854 7.3043 52.5219L11.8352 42.216L15.8311 33.2688C15.9878 32.8481 16.3953 32.5557 16.8721 32.5714H17.125H17.2079C20.8792 32.6929 28.1949 32.9381 32.54 37.2193C36.9098 33.0371 44.1449 32.8436 47.8453 32.7694H47.9282H48.1677C48.6154 32.7154 49.0631 32.9584 49.2557 33.3948L57.3549 52.4184C61.7582 46.8954 64.3953 39.8875 64.3953 32.2587C64.3953 14.4454 50.0213 0 32.2915 0Z"
			/>
			<path
				fill="#fefdfe"
				d="M44.933 8.98078C44.8413 9.79968 44.4249 10.3171 43.5809 10.2901C42.7549 10.2631 42.2781 9.74118 42.2893 8.92454C42.2982 8.13938 42.8153 7.69619 43.5765 7.67819C44.4114 7.6602 44.8345 8.17313 44.933 8.98078Z"
			/>
			<path
				fill="#fefdfe"
				d="M18.7183 18.3078C17.899 18.247 17.3505 17.8421 17.3438 17.0119C17.3371 16.274 17.7423 15.7408 18.5415 15.6936C19.3742 15.6419 19.9026 16.0761 19.9585 16.8972C20.0145 17.7251 19.5018 18.1705 18.7183 18.3078Z"
			/>
			<path
				fill="#fefdfe"
				d="M58.4085 30.2742C58.3235 31.1831 57.8825 31.6511 57.0497 31.6443C56.2483 31.6376 55.8095 31.1179 55.7916 30.38C55.7715 29.5746 56.2684 29.0211 57.0833 29.0166C57.8892 29.0121 58.3324 29.5341 58.4108 30.2742H58.4085Z"
			/>
			<path
				fill="#fefdfe"
				d="M6.51091 37.6084C7.24965 37.7029 7.76452 38.1078 7.76229 38.8952C7.76229 39.7231 7.25636 40.2518 6.42808 40.2316C5.60651 40.2113 5.10059 39.6826 5.15656 38.8435C5.2058 38.0808 5.6871 37.6421 6.51091 37.6084Z"
			/>
			<path fill="#cf2131" d="M28.9372 17.8331L22.4609 25.0187H40.316L31.5049 15.0322C30.6296 15.9884 29.7789 16.9152 28.9372 17.8331Z" />
			<path
				fill="white"
				d="M33.9374 12.3063C34.4791 11.6988 35.0209 11.0914 35.5626 10.484L37.2841 8.57397C37.5774 8.25226 37.8728 7.93055 38.1683 7.61109C38.2556 7.5166 38.3318 7.42886 38.3922 7.34337L38.8914 6.78994L37.2707 5.31413L32.9233 10.2185C32.8181 10.3175 32.7017 10.4255 32.5651 10.5447C32.5651 10.0768 32.5606 9.63584 32.5562 9.20839V5.21289H30.4541V6.47273C30.4474 6.55597 30.4452 6.64596 30.4474 6.7427C30.4519 6.95192 30.4519 7.16115 30.4541 7.36812V9.52335C30.4541 9.85856 30.4541 10.1915 30.4541 10.5267L25.8762 5.33662L25.2248 5.91705C25.1419 5.97779 25.0569 6.04754 24.9628 6.13303C24.9024 6.18702 24.8509 6.24101 24.8039 6.29051L24.2398 6.79444L24.7166 7.33438C24.7994 7.44461 24.9002 7.55935 25.0166 7.68533C25.1397 7.82031 25.2606 7.9553 25.3815 8.08803L30.0825 13.4154C26.118 17.8676 22.2497 22.2163 18.209 26.7539H44.7991C40.7427 22.1645 36.9013 17.8181 33.0128 13.4176C33.3755 12.9789 33.6419 12.628 33.9351 12.3018L33.9374 12.3063ZM40.3174 25.0194H22.4623L28.9386 17.8338C29.7803 16.9159 30.631 15.9868 31.5063 15.0329L40.3174 25.0194Z"
			/>
			<path
				fill="url(#pattern0_1_2)"
				fillOpacity="0.46"
				d="M47.6071 34.9541C43.9738 35.0418 37.1663 35.3096 33.5912 39.2533L33.5039 52.9586C39.2952 48.7719 48.0391 48.3512 53.2551 48.2432L47.6071 34.9541Z"
			/>
			<path
				fill="#6636c6"
				d="M47.6071 34.9541C43.9738 35.0418 37.1663 35.3096 33.5912 39.2533L33.5039 52.9586C39.2952 48.7719 48.0391 48.3512 53.2551 48.2432L47.6071 34.9541Z"
			/>
			<path
				fill="url(#pattern1_1_2)"
				fillOpacity="0.46"
				d="M31.4326 39.2062C27.918 35.2534 21.1395 34.88 17.513 34.7607L11.668 47.9733C16.8839 48.1488 25.6122 48.6865 31.343 52.943L31.4303 39.2084L31.4326 39.2062Z"
			/>
			<path
				fill="#6636c6"
				d="M31.4326 39.2062C27.918 35.2534 21.1395 34.88 17.513 34.7607L11.668 47.9733C16.8839 48.1488 25.6122 48.6865 31.343 52.943L31.4303 39.2084L31.4326 39.2062Z"
			/>
			<path
				fill="white"
				stroke="white"
				strokeMiterlimit="10"
				d="M57.2362 52.8489L48.9668 33.6611C48.7743 33.2246 48.6422 33.1459 48.1945 33.1999H47.8094H47.7266C44.0262 33.2741 36.791 33.4676 32.4213 37.6498C28.0762 33.3686 20.7604 33.1234 17.0891 33.0019H17.0063H16.5071C16.0303 32.9862 15.9721 33.0604 15.8153 33.4811L11.7165 42.6465L7.18555 52.9524C7.96234 53.9198 8.7951 54.8399 9.67711 55.7106C11.9941 55.6363 15.0453 55.9918 18.2196 56.7882C21.7588 57.6701 25.4212 59.0874 28.2978 61.0379C28.3246 61.0649 28.3672 61.0942 28.394 61.1099C28.994 61.4924 29.623 61.7938 30.2655 62.0098C30.9102 62.2258 31.5527 62.327 32.2109 62.318H32.2534C32.925 62.3383 33.5854 62.2438 34.2457 62.0368C34.8636 61.8433 35.4814 61.5509 36.0724 61.1886C36.0993 61.1752 36.1284 61.1459 36.1553 61.1324C39.0677 59.2066 42.777 57.8208 46.3566 56.9704C49.2981 56.2663 52.1411 55.9446 54.3909 55.985C55.405 55.0064 56.3564 53.9626 57.2362 52.8579V52.8489ZM33.4712 39.6768C37.0462 35.7331 43.8538 35.4654 47.4871 35.3776L53.135 48.6667C47.9191 48.7747 39.1751 49.1954 33.3839 53.3821L33.4712 39.6768ZM17.3958 35.1841C21.0223 35.3034 27.8008 35.6768 31.3154 39.6296L31.2281 53.3641C25.4973 49.1099 16.769 48.5722 11.5531 48.3945L17.398 35.1819L17.3958 35.1841ZM45.8775 54.8422C42.0876 55.7353 38.1543 57.2156 35.0158 59.2809L34.989 59.2944L34.9755 59.3079C34.5099 59.5891 34.0599 59.8096 33.6257 59.9491C33.1914 60.0863 32.7548 60.1403 32.3228 60.1245C32.2668 60.111 32.2109 60.1245 32.1527 60.1245C31.7452 60.1358 31.34 60.0615 30.9349 59.9333C30.4737 59.7758 30.0125 59.5464 29.538 59.2471C29.5089 59.2336 29.4954 59.2336 29.482 59.2201C26.3838 57.1324 22.4976 55.6138 18.7345 54.6712C15.2423 53.8073 11.8463 53.4339 9.27192 53.5509L10.6128 50.5205C16.0034 50.687 26.3927 51.1279 31.5146 56.4552C31.9355 56.8804 32.6205 56.8984 33.0436 56.4777C33.057 56.4507 33.0861 56.435 33.0996 56.408C38.3178 51.1909 48.6601 50.8827 54.0394 50.7882L55.3378 53.8456C52.7657 53.6836 49.372 54.0143 45.8797 54.8444L45.8775 54.8422Z"
			/>
			<path
				fill="#6636c6"
				d="M18.8515 54.319C22.6146 55.2594 26.5031 56.7779 29.599 58.8679C29.6125 58.8814 29.6259 58.8814 29.655 58.8949C30.1296 59.1919 30.5907 59.4236 31.0519 59.5811C31.4571 59.7116 31.8623 59.7836 32.2697 59.7723C32.3257 59.7723 32.3839 59.7588 32.4398 59.7723C32.8741 59.7881 33.3084 59.7341 33.7427 59.5968C34.177 59.4596 34.6269 59.2369 35.0926 58.9557L35.106 58.9422L35.1329 58.9287C38.2736 56.8634 42.2046 55.3831 45.9945 54.49C49.489 53.6598 52.8827 53.3291 55.4526 53.4911L54.1542 50.4337C48.7771 50.5305 38.4348 50.8364 33.2144 56.0535C33.2009 56.0805 33.1718 56.0963 33.1584 56.1233C32.7353 56.5417 32.0503 56.5237 31.6294 56.1008C26.5053 50.7734 16.1182 50.3325 10.7276 50.166L9.38672 53.1964C11.9589 53.0794 15.3571 53.4529 18.8493 54.3167L18.8515 54.319Z"
			/>
		</SvgIcon>
	)
}

export default TlichoIconAtom
